import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import scheduleApolloClient from '@/lib/appsync/schedule';
import TasqJob from '@/interfaces/tasqs/TasqJob';

import completePrePostTasqJob from '@/graphql/schedule/mutations/completePrePostTasqJob.graphql';
import getWorkspaces from '@/graphql/schedule/queries/getWorkspaces.graphql';
import getTables from '@/graphql/schedule/queries/getTables.graphql';
import getGroups from '@/graphql/schedule/queries/getGroups.graphql';
import accountModule from '@/store/modules/accountModule';
import { mapDefaultTasq } from '@/utils/tasqs';

import getScheduleRulesResponse from '@/graphql/userSettings/queries/getScheduleRulesResponse.graphql';



@Module({
  dynamic: true,
  namespaced: true,
  name: 'workspaceModule',
  store,
})
class WorkspaceModule extends VuexModule {
//   availableSignals = getConfigEnv('SIGNALS_ARRAY');

  activeWorkspaces: any[] = []
  activeTables: any[] = []
  activeGroups: any[] = []
  totalItems: any[] = []

  activePage: any = ''

  activeWorkspace: {}|null|undefined = null;
  activeTable: {}|null|undefined = null;
  activeGroup: {}|null|undefined = null;


  navBarOptions: any[] = []

  @Mutation
  resetNavBarOptions(): void {
    this.navBarOptions = [];
  }

  @Mutation
  setNavBarOptions(options): void {
    this.navBarOptions = options;
  }




  @Mutation
  resetWorkspaces(): void {
    this.activeWorkspaces = [];
  }

  @Mutation
  setWorkspaces(workspaces): void {
    this.activeWorkspaces = workspaces;
  }



  @Mutation
  setActiveWorkspace(id: string): void {
	this.activeWorkspace = this.activeWorkspaces.find((i) => i.ID === id);
  }


  @Mutation
  setTotalItems() {
	  var items: any[] = []
	  // @ts-ignore
		for (var y = 0; y < this.activeWorkspace.Tables.length; y++) {
			// @ts-ignore
			for (var z = 0; z < this.activeWorkspace.Tables[y].Groups.length; z++) {
				// @ts-ignore
				for (var a = 0; a < this.activeWorkspace.Tables[y].Groups[z].Items.length; a++) {
					// @ts-ignore
					var t = mapDefaultTasq(this.activeWorkspace.Tables[y].Groups[z].Items[a])
					// @ts-ignore
					items.push(t)
				}
			}
		}
	this.totalItems = items
  }

  @Action
  setActiveWorkspaceAction({id, existingTableID}): void {

	this.setActiveWorkspace(id)
	// @ts-ignore
	this.setTables(this.activeWorkspace.Tables)

	var navBarOptionsLocal = [
		{
			id: "create",
			type: "add_view",
			name: "Add schedule",
			icon: "plus"
		}
	]
	// @ts-ignore
	for (var x = 0; x < this.activeWorkspace.Tables.length; x++) {
		// @ts-ignore
		navBarOptionsLocal.push({
			// @ts-ignore
			id: this.activeWorkspace.Tables[x].ID,
			// @ts-ignore
			type: this.activeWorkspace.Tables[x].Type,
			// @ts-ignore
			name: this.activeWorkspace.Tables[x].TableTitle,
			icon: 'date_range'
		})
	}
	this.setNavBarOptions(navBarOptionsLocal)

	if (existingTableID != null) {

		this.setActiveTable({id: existingTableID})
	}
  }


  @Mutation
  setActivePage(page): void {
	this.activePage = page
  }



  @Mutation
  resetTables(): void {
    this.activeTables = [];
  }

  @Mutation
  setTables(tables): void {
    this.activeTables = tables;
  }

  @Mutation
  setActiveTable({id}): void {
    this.activeTable = this.activeTables.find((i) => i.ID === id);

  }




  @Mutation
  resetGroups(): void {
    this.activeGroups = [];
  }

  @Mutation
  setGroups(workspaces): void {
    this.activeGroups = workspaces;
  }

  @Mutation
  setActiveGroup(id: string): void {
    this.activeGroup = this.activeGroups.find((i) => i.id === id);
  }




  @Action
  async getWorkspaces(existing_table_id: any = null) {

	// var workspaces = [
	// 	{
	// 		id: "1",
	// 		type: "rig_scheduler",
	// 		name: "Rig scheduler",
	// 		icon: "date_range"
	// 	},
	// 	{
	// 		id: "2",
	// 		type: "well_test",
	// 		name: "Well test",
	// 		icon: "date_range"
	// 	},
	// 	{
	// 		id: "create",
	// 		type: "add_view",
	// 		name: "Add view",
	// 		icon: "plus"
	// 	}
	//   ];

    let usage = "SCHEDULE"
  if(this.activePage === 'Groups'){
    usage = "ASSETS"
  }
	  

    const {
      data: {
        get_workspaces: workspaces,
      },
    } = await scheduleApolloClient.query({
      query: getWorkspaces,
      variables: {
        input: {
          Operator: getConfigEnv('OPERATOR_LOWERCASED'),
		  Username: accountModule.user.email.toLowerCase(),
		  CreateWorkspaceIfNotExists: true,
		  Usage: usage
        },
      },
    });
	var parsed_workspaces = JSON.parse(workspaces.Results)
	var workspaces_array: any[] = []
	for (const [key, value] of Object.entries(parsed_workspaces)) {
		workspaces_array.push(value)
	}
	this.setWorkspaces(workspaces_array)
  if(existing_table_id){
	  
    this.setActiveWorkspaceAction({
		id: workspaces_array[0].ID,
		existingTableID: existing_table_id
	})
  }else {
    this.setActiveWorkspace(workspaces_array[0].ID)
	if (workspaces_array[0].Tables.length > 0) {
		
		this.setActiveWorkspaceAction({
			id: workspaces_array[0].ID,
			existingTableID: workspaces_array[0].Tables[0].ID
		})
	}
	
  }


//   var key = Object.keys(parsed_workspaces)[0]
//   	// @ts-ignore
// 	  if (parsed_workspaces[key].Tables.length > 0) {
// 		  // @ts-ignore
// 		  this.setTables(parsed_workspaces[key].Tables)
// 	  }
  }







  get tasqById() {
    return (id: string): TasqJob|undefined => [...this.totalItems].find((t) => t.id.toLowerCase() === id.toLowerCase());
  }









}










export default getModule(WorkspaceModule);
